/* eslint no-console: 0 */
import * as cheerio from 'cheerio';

const $ = cheerio.load;

type MaybeValidJSONSchema = {
  ['@type']?: 'Recipe';
  name?: string;
  description?: string;
  recipeIngredient?: string[];
};

class TreeValidator {
  protected siteTree: cheerio.CheerioAPI;
  constructor(tree: cheerio.CheerioAPI) {
    this.siteTree = tree;
  }
}

const selectionTree: { [k: string]: string } = {
  // Default/Chicory
  '#recipe': '#recipe-ingredients',
  // WPRM
  '.wprm-recipe-container': '.wprm-recipe-ingredients-container',
  // WPRUP
  '.wpurp-container': '.wpurp-recipe-ingredients',
  // Mediavine
  '.mv-recipe-card': '.mv-create-ingredients',
  // HRecipe V1
  '.hrecipe': '.ingredient',
  // HRecipe V2
  '.h-recipe': '.p-ingredient',
  // Microdata
  '[itemtype*="schema.org/Recipe"], #mpprecipe-ingredients-list':
    '[itemprop="ingredients"], [itemprop="recipeIngredient"]',
};

class PageStructureValidator extends TreeValidator {
  private findWithSelectors(
    recipeContainerSelector: string,
    ingredientsContainerSelector: string,
    useIngredientsParentStrategy: boolean = false
  ) {
    const recipeContainer = this.siteTree('body').find(recipeContainerSelector);
    let ingredientsContainer = recipeContainer.find(
      ingredientsContainerSelector
    );
    if (useIngredientsParentStrategy && ingredientsContainer.length > 0) {
      // This strategy matches the matching pattern for HRecipe/Microdata taken by dom.js
      ingredientsContainer = ingredientsContainer.parent().eq(-1);
    }

    const recipeContainerEle = recipeContainer.get(0);
    const ingredientsContainerEle = ingredientsContainer.get(0);

    type SelectionDetails = {
      element: Exclude<typeof recipeContainerEle, undefined>;
      selector: string;
    };

    const output: {
      recipeContainer: SelectionDetails | null;
      ingredientsContainer: SelectionDetails | null;
    } = {
      recipeContainer: null,
      ingredientsContainer: null,
    };

    if (recipeContainerEle) {
      output.recipeContainer = {
        element: recipeContainerEle,
        selector: recipeContainerSelector,
      };
    }

    if (ingredientsContainerEle) {
      output.ingredientsContainer = {
        element: ingredientsContainerEle,
        selector: recipeContainerSelector,
      };
    }

    return output;
  }
  private checkForDefault = () => {
    return this.findWithSelectors('#recipe', '#recipe-ingredients');
  };
  private checkForWPRM = () => {
    return this.findWithSelectors(
      '.wprm-recipe-container',
      '.wprm-recipe-ingredients-container'
    );
  };
  private checkForWPURP = () => {
    return this.findWithSelectors(
      '.wpurp-container',
      '.wpurp-recipe-ingredients'
    );
  };
  private checkForMediavine = () => {
    return this.findWithSelectors('.mv-recipe-card', '.mv-create-ingredients');
  };
  private checkForHRecipeV1 = () => {
    return this.findWithSelectors('.hrecipe', '.ingredient', true);
  };
  private checkForHRecipeV2 = () => {
    return this.findWithSelectors('.h-recipe', '.p-ingredient', true);
  };
  private checkForMicrodata = () => {
    return this.findWithSelectors(
      '[itemtype*="schema.org/Recipe"], #mpprecipe-ingredients-list',
      '[itemprop="ingredients"], [itemprop="recipeIngredient"]',
      true
    );
  };
  validate() {
    const resultSetRunners = [
      this.checkForDefault,
      this.checkForWPRM,
      this.checkForWPURP,
      this.checkForMediavine,
      this.checkForHRecipeV2,
      this.checkForHRecipeV1,
      this.checkForMicrodata,
    ];

    const partialResults = [];

    for (const runner of resultSetRunners) {
      const resultOutput = runner();
      const neitherNull =
        resultOutput.recipeContainer !== null &&
        resultOutput.ingredientsContainer !== null;
      const onlyOneNull =
        resultOutput.recipeContainer !== null ||
        resultOutput.ingredientsContainer !== null;
      if (neitherNull) {
        return { valid: true, messages: [] };
      } else if (onlyOneNull) {
        partialResults.push(resultOutput);
      }
    }

    if (partialResults.length > 0) {
      return {
        valid: false,
        messages: partialResults.map((result) => {
          let errorMessage = '';
          errorMessage += 'Detected a partially configured page structure.';
          if (result.recipeContainer !== null) {
            errorMessage += `We found a recipe container using selector: ${result.recipeContainer.selector}, 
              but could not find an ingredients container with expected selector: ${selectionTree[result.recipeContainer.selector]}.
              The ingredients container must be nested within the recipe container.`;
          }
          return errorMessage;
        }),
      };
    }

    return {
      valid: false,
      messages: ['Cannot find any expected recipe page structure.'],
    };
  }
}

class ScriptInstallationValidator extends TreeValidator {
  checkForLikelySinglePageInstallation() {
    const scriptsThatHaveChicoryURL = this.siteTree('script')
      .map((_, scriptElement) => {
        return $(scriptElement).text();
      })
      .filter((_, scriptBody) => {
        return scriptBody.includes('https://chicoryapp.com/widget_v2');
      });
    return scriptsThatHaveChicoryURL.length > 0;
  }
  validate() {
    const allScripts = this.siteTree('script');
    const chicoryInstallScripts = allScripts.filter((_, scriptElement) => {
      return scriptElement.attribs.src?.includes('widget_v2') || false;
    });

    if (chicoryInstallScripts.length === 1) {
      return { valid: true, messages: [] };
    } else if (chicoryInstallScripts.length > 1) {
      return {
        valid: false,
        messages: ['Detected more than one inclusion of Chicory installation.'],
      };
    } else {
      if (this.checkForLikelySinglePageInstallation() === true) {
        return { valid: true, messages: [] };
      }
      return {
        valid: false,
        messages: ['Could not detect Chicory installation script.'],
      };
    }
  }
}

class IngredientsInHTMLValidator extends TreeValidator {
  private expectedSingleIngredientSelectors = [
    'li.wprm-recipe-ingredient',
    'li.wpurp-recipe-ingredient',
    'span[itemprop="ingredients"]',
    'span[itemprop="recipeingredient"]',
    'li[itemprop="ingredients"]',
    'li.ingredient',
    'li[itemprop="recipeingredient"]',
    'div[itemprop="recipeingredient"]',
    'div[itemprop="ingredient"]',
    'label[itemprop="recipeingredient"]',
    'p.ingredient',
  ];
  private collectPotentialIngredientElements() {
    const selectorToIngredients = this.expectedSingleIngredientSelectors
      .map((selector) => {
        const foundElements = this.siteTree(selector);
        return { selector, elements: foundElements };
      })
      .filter(({ elements }) => {
        return elements.length > 0;
      });

    if (selectorToIngredients.length === 0) {
      return {
        valid: false,
        messages: ['No expected ingredient elements were found.'],
      };
    } else {
      return {
        valid: true,
        messages: [],
      };
    }
  }
  validate() {
    return this.collectPotentialIngredientElements();
  }
}

class JSONLDSchemaValidator extends TreeValidator {
  private maybeExtractSchema(parsedSchema: any) {
    if (parsedSchema['@graph']) {
      if (Array.isArray(parsedSchema['@graph'])) {
        const recipeSchema = parsedSchema['@graph'].find(
          (obj) => obj['@type'] === 'Recipe'
        );
        if (!recipeSchema) {
          throw new Error(
            'Parsed JSON-LD is a graph but is missing @type:Recipe.'
          );
        }
        return recipeSchema;
      }
    } else if (Array.isArray(parsedSchema)) {
      const recipeSchema = parsedSchema.find(
        (obj) => obj['@type'] === 'Recipe'
      );
      if (!recipeSchema) {
        throw new Error(
          'Parsed JSON-LD is a list but is missing @type:Recipe.'
        );
      }
      return recipeSchema;
    } else {
      return parsedSchema;
    }
  }
  private detectCorrectSchemaFromContents(contents: string[]) {
    const positionOfCorrectSchema = contents.findIndex((textContent) => {
      try {
        const parsed = this.maybeExtractSchema(JSON.parse(textContent));
        if (parsed['@type'] && parsed['@type'] === 'Recipe') {
          return true;
        }
      } catch (e) {
        console.error(e);
      }
      return false;
    });
    return positionOfCorrectSchema;
  }
  private validateSchemaContent(parsedJSONLDSchema: MaybeValidJSONSchema) {
    if (
      !parsedJSONLDSchema['@type'] ||
      parsedJSONLDSchema['@type'] !== 'Recipe'
    ) {
      throw new Error('Parsed JSON-LD schema is missing @type:Recipe');
    }

    if (!parsedJSONLDSchema.name) {
      throw new Error('Parsed JSON-LD schema is missing a valid "name".');
    } else if (typeof parsedJSONLDSchema.name !== 'string') {
      throw new Error('Parsed JSON-LD schema "name" must be a string.');
    }

    if (!parsedJSONLDSchema.description) {
      throw new Error(
        'Parsed JSON-LD schema is missing a valid "description".'
      );
    } else if (typeof parsedJSONLDSchema.description !== 'string') {
      throw new Error('Parsed JSON-LD schema "description" must be a string.');
    }

    if (
      !parsedJSONLDSchema.recipeIngredient ||
      !Array.isArray(parsedJSONLDSchema.recipeIngredient)
    ) {
      throw new Error(
        'Parsed JSON-LD schema "recipeIngredient" must be an array.'
      );
    } else if (parsedJSONLDSchema.recipeIngredient.length === 0) {
      throw new Error(
        'Parsed JSON-LD schema "recipeIngredient" must contain some ingredients.'
      );
    }
  }
  private getJSONSchemaScriptElementText() {
    const likelyElements = this.siteTree('script').filter((_, element) => {
      return element.attribs.type === 'application/ld+json';
    });
    if (likelyElements.length === 0) {
      throw new Error('Document missing application/ld+json <script> element.');
    }
    if (likelyElements.length === 1) {
      return likelyElements.first().text();
    } else {
      const jsonContents = likelyElements
        .map((_, element) => {
          return $(element).text();
        })
        .toArray();
      const correctSchemaPosition =
        this.detectCorrectSchemaFromContents(jsonContents);

      if (correctSchemaPosition !== -1) {
        const element = likelyElements.get(correctSchemaPosition);
        if (element) {
          return $(element).text();
        }
      } else {
        throw new Error(
          'Found more than one application/ld+json schema element, but none are @type:Recipe.'
        );
      }
    }
  }
  validate() {
    const issueMessages = [];
    let scriptElementText;
    try {
      scriptElementText = this.getJSONSchemaScriptElementText();
    } catch (e) {
      if (e instanceof Error) {
        issueMessages.push(e.message);
      }
    }

    if (!scriptElementText) {
      if (issueMessages.length === 0) {
        issueMessages.push('Could not find JSON-LD script element.');
      }
      return { valid: false, messages: issueMessages };
    }
    const jsonText = scriptElementText;

    let successfullyParsedJSON;
    try {
      successfullyParsedJSON = this.maybeExtractSchema(JSON.parse(jsonText));
    } catch (e) {
      issueMessages.push(
        'Could not parse the content of the JSON-LD script element.'
      );
    }

    try {
      this.validateSchemaContent(successfullyParsedJSON);
    } catch (e) {
      if (e instanceof Error) {
        issueMessages.push(e.message);
      } else {
        throw e;
      }
    }

    return { valid: issueMessages.length === 0, messages: issueMessages };
  }
}

class RecipePageClientValidationSystem {
  private testIfStringIsHTML(str: string) {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  }
  private extractRelevantHeadersValues(headers: Headers) {
    return {
      contentType: headers.get('Content-Type'),
    };
  }
  private analyzeResponseText(responseText: string) {
    if (responseText === '') throw new Error('Response text is empty.');
    return {
      value: responseText,
      length: responseText.length,
    };
  }
  private prepareURL(url: string): string {
    return `https://corsproxy.io/?${url}`;
  }
  private validateIngredientsInHTML(tree: cheerio.CheerioAPI) {
    const validator = new IngredientsInHTMLValidator(tree);
    return validator.validate();
  }
  private validateJSONLDSetup(tree: cheerio.CheerioAPI) {
    const schemaValidator = new JSONLDSchemaValidator(tree);
    return schemaValidator.validate();
  }
  private validateScriptInstallation(tree: cheerio.CheerioAPI) {
    const validator = new ScriptInstallationValidator(tree);
    return validator.validate();
  }
  private validatePageStructure(tree: cheerio.CheerioAPI) {
    const validator = new PageStructureValidator(tree);
    return validator.validate();
  }
  private createCheerioParser(htmlText: string) {
    return $(htmlText);
  }
  public async processURL(url: string): Promise<true | string[]> {
    try {
      const serverResponse = await fetch(this.prepareURL(url));
      if (!serverResponse.ok) {
        return [
          `Document could not be retrieved. Server responded with a ${serverResponse.status} status code.`,
        ];
      }

      const responseBodyText = await serverResponse.text();
      const seemsLikeHTML = this.testIfStringIsHTML(responseBodyText);

      if (!seemsLikeHTML) {
        throw new Error('Response body is not HTML.');
      }

      const tree = this.createCheerioParser(responseBodyText);

      const { valid: installationScriptValid } =
        this.validateScriptInstallation(tree);

      const { valid: jsonLDValid, messages: jsonLDIssues } =
        this.validateJSONLDSetup(tree);

      const { valid: pageStructureValid, messages: pageStructureMessages } =
        this.validatePageStructure(tree);

      const { valid: htmlMetadataValid } = this.validateIngredientsInHTML(tree);

      if (
        installationScriptValid &&
        (jsonLDValid || htmlMetadataValid) &&
        pageStructureValid
      ) {
        return true;
      } else {
        const reportedMessages = [];
        if (!installationScriptValid) {
          reportedMessages.push('Missing installation script');
        }
        if (!jsonLDValid && !htmlMetadataValid) {
          reportedMessages.push(...jsonLDIssues);
        }
        if (!pageStructureValid) {
          reportedMessages.push(...pageStructureMessages);
        }
        return reportedMessages;
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return [e.message];
      } else {
        return ['Processing error. Please try again.'];
      }
    }
  }
}

export default RecipePageClientValidationSystem;
