import React, { useState } from 'react';

import './Step3.pcss';
import { Icon } from '../../components/icons';
import { Tiles } from '../../components/Tiles';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Form } from '../../components/Form';
import { Link, Navigate } from 'react-router-dom';
import { updatePaypalEmail } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';

import RFC822EmailRegex from '../SignupPage/helpers/email-regex';
const verifyEmail = (emailValue: string) => RFC822EmailRegex.test(emailValue);

const Step3Payments: React.FC = () => {
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useIsMobile();
  const [email, setEmail] = useState(user?.paypalEmail || '');
  const [validationError, setValidationError] = useState<boolean>(false);
  const [expandText, setExpandText] = useState(false);
  const [redirect, setRedirect] = useState('');

  const handleSubmit = async () => {
    setValidationError(false);
    if (email === '') {
      setRedirect('/onboarding/step4');
    } else if (!verifyEmail(email)) {
      setValidationError(true);
    } else {
      await dispatch(updatePaypalEmail(email));
      setRedirect('/onboarding/step4');
    }
  };

  // Assembly order depends on isMobile
  const sentence1 = (
    <span className="sentence1">{`You're almost part of the Chicory Network. `}</span>
  );
  const sentence2 = (
    <p style={isMobile ? { marginTop: '5px' } : {}}>
      {`The best part is getting paid for your recipes affiliation earnings.`}
    </p>
  );
  const header = <h1 className="header">{`Step 3 of 3: Payments`}</h1>;
  const icon = (
    <div className="icon-holder">
      <Icon
        name="coins"
        style={{
          width: isMobile ? '75px' : '150px',
          height: isMobile ? '75px' : '150px',
          marginRight: isMobile ? '20px' : '0',
          marginLeft: isMobile ? '5px' : '0',
        }}
      />
    </div>
  );
  const paypalFolded = (
    <div
      id="paypal-folded"
      onClick={() => {
        setExpandText((prev) => !prev);
      }}
      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
    >
      <Icon
        name="circleChevronRight"
        style={{ marginRight: '10px' }}
        className={`icon-container ${expandText ? 'rotated' : ''}`}
      />
      <span>Instructions if you do not have a PayPal email</span>
    </div>
  );
  const paypalText = (
    <div id="paypal-text">
      Chicory makes payments to our partners via PayPal.{' '}
      <a
        href="http://www.paypal.com"
        target="_blank"
        rel="noreferrer noopener"
        className="blue-link"
      >
        Sign up with PayPal now
      </a>
      , and come back to enter the email here. <br />
      If you prefer to sign up at another time, you can provide the email you
      associate with your PayPal account to Chicory via our partner portal.
    </div>
  );
  // End small components

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <div id="onboarding-step3-container">
      {isMobile ? null : (
        <h2 className="getting-started__header">
          Getting started with Chicory
        </h2>
      )}
      <div className="broad-container">
        {isMobile ? null : icon}
        <div className="main-flex-column">
          {header}
          <div>
            {isMobile ? (
              <div className="mobile-icon-and-line-break">
                {icon}
                {sentence1}
              </div>
            ) : (
              sentence1
            )}
            {isMobile ? <div style={{ marginTop: '10px' }}></div> : null}
            {sentence2}
          </div>

          <p>
            To complete your registration and set up payments, enter your email
            on your PayPal account.
          </p>
          <Form.Label style={{ marginTop: '20px' }}>
            PayPal email (optional)
          </Form.Label>
          <div className="wrap-on-mobile">
            <Form.InputContainer
              style={{
                width: isMobile ? '100%' : '70%',
                border: validationError ? '1px solid red' : '',
                marginRight: '10px',
                lineHeight: '45px',
              }}
            >
              <input
                placeholder="Enter PayPal email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.InputContainer>
            {validationError && <span className="red">Please provide a valid email.</span>}
          </div>
          {paypalFolded}
          {expandText ? paypalText : null}
          <p>
            To protect your privacy, we will verify everything on our end and
            reach out to you when you’re ready to start. Typically within 20,000
            business days.
          </p>
          {!isMobile && (
            <p>
              If you have any questions or need support with installation,
              please reach out to{' '}
              <a
                href="mailto:publishers@chicory.co"
                target="_blank"
                rel="noreferrer noopener"
                className="blue-link"
              >
                publishers@chicory.co
              </a>
              .
            </p>
          )}
          <Tiles
            style={{
              width: '100%',
              height: 'auto',
              border: 'none',
              justifyContent: 'space-between',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              marginTop: '20px',
              marginBottom: '90px',
            }}
          >
            <Link
              to={{ pathname: '/onboarding/step2' }}
              style={{
                width: isMobile ? '100%' : '30%',
              }}
            >
              <Tiles.Button
                version="clear"
                style={{
                  width: isMobile ? '100%' : '30%',
                  height: '45px',
                  fontSize: '16px',
                  fontWeight: 100,
                  color: 'purple',
                  border: 'none',
                  justifyContent: 'flex-start',
                  paddingLeft: '0',
                  marginTop: '15px',
                }}
              >
                Previous
              </Tiles.Button>
            </Link>

            <Tiles.Button
              version="darkPurple"
              style={{
                width: isMobile ? '100%' : '180px',
                height: '45px',
                fontSize: '16px',
                fontWeight: 400,
                border: 'none',
                marginTop: '15px',
              }}
              onClick={handleSubmit}
            >
              Next
            </Tiles.Button>
          </Tiles>
        </div>
      </div>
    </div>
  );
};

export default Step3Payments;
