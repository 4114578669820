import React from 'react';
import alertCircle from './alert-circle.svg';
import arrowLeft from './arrow-left.svg';
import linkExternal from './link-external.svg';
import pencil from './pencil.svg';
import logOut from './log-out.svg';
import chevronUp from './chevron-up.svg';
import chevronDown from './chevron-down.svg';
import homeLine from './home-line.svg';
import barChartSquare02 from './bar-chart-square-02.svg';
import lifeBuoy from './life-buoy.svg';
import user01 from './user-01.svg';
import arrowRight from './arrow-right.svg';
import helpCircle from './help-circle.svg';
import calendar from './calendar.svg';
import eye from './eye.svg';
import cursorClick from './cursor-click-01.svg';
import building02 from './building-02.svg';
import shoppingCart from './shopping-cart.svg';
import globe from './globe.svg';
import download02 from './download-02.svg';
import downloadCloud from './download-cloud.svg';
import clockRefresh from './clock-refresh.svg';
import trash from './trash.svg';
import edit from './edit.svg';
import upload from './upload-cloud.svg';
import xClose from './x-close.svg';
import check from './check.svg';
import checkCircle from './check-circle.svg';
import settings from './settings.svg';
import plus from './plus.svg';
import search from './search-mag-glass.svg';
import install from './install.svg';
import groceries from './groceries.svg';
import circleChevronRight from './circle-chevron-right.svg';
import coins from './coins.svg';
import utensils from './utensils.svg';
import checkCircle2 from './checkCircle.svg';
import exclamationOrange from './exclamationOrange.svg';

const iconRepo: Record<string, string> = {
  'alert-circle': alertCircle,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'link-external': linkExternal,
  pencil: pencil,
  'log-out': logOut,
  'chevron-up': chevronUp,
  'chevron-down': chevronDown,
  'home-line': homeLine,
  'bar-chart-square-02': barChartSquare02,
  'life-buoy': lifeBuoy,
  'user-01': user01,
  'help-circle': helpCircle,
  calendar: calendar,
  eye: eye,
  'cursor-click-01': cursorClick,
  'building-02': building02,
  'shopping-cart': shoppingCart,
  globe: globe,
  download02: download02,
  downloadCloud: downloadCloud,
  clockRefresh: clockRefresh,
  trash: trash,
  edit: edit,
  upload: upload,
  x: xClose,
  check: check,
  'check-circle': checkCircle,
  settings,
  plus,
  search,
  install,
  groceries,
  circleChevronRight,
  coins,
  utensils,
  checkCircle2,
  exclamationOrange,
};

export const Icon = ({
  name,
  width = '20px',
  height = '20px',
  style = {},
  onClick,
  className,
}: {
  name: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}) => {
  const iconHTML: string = iconRepo[name] || '';
  return (
    <div
      style={{ width: width, height: height, ...style }}
      dangerouslySetInnerHTML={{ __html: iconHTML }}
      onClick={onClick}
      className={className}
    ></div>
  );
};
