declare global {
  const __CHICORYCONFIG__: {
    API: {
      host: string;
      analytics_endpoint: string;
    };
    CDN: { host: string };
    ENVIRONMENT: string;
    DATADOG_CLIENT_TOKEN: string | null;
  };
}

declare global {
  const __VERSION__: string;
}

export const apiUrl = __CHICORYCONFIG__.API.host;
export const cdnUrl = __CHICORYCONFIG__.CDN.host;
export const analyticsUrl = __CHICORYCONFIG__.API.analytics_endpoint;
export const environment = __CHICORYCONFIG__.ENVIRONMENT;
export const datadogClientToken = __CHICORYCONFIG__.DATADOG_CLIENT_TOKEN;
export const version = __VERSION__;

export const MOBILE_VIEW_MAX_WIDTH = 707;
