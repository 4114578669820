import { createReducer, createAction } from '@reduxjs/toolkit';
import { Report } from '../types';

// Create
// Create
type CreatingSchedule = boolean;

export const creatingSchedule = createAction<
  CreatingSchedule,
  'CREATING_SCHEDULE'
>('CREATING_SCHEDULE');

export const creatingScheduleReducer = createReducer<boolean>(
  false, // initial state
  (builder) => {
    builder.addCase(creatingSchedule, (state, action) => {
      return action.payload;
    });
  }
);

// Confirm
// Confirm
type SavingReport = (Report & { updatingSchedule?: boolean }) | null;

const initialStateConfirm: SavingReport = null;

export const confirmingSchedule = createAction<
  SavingReport,
  'CONFIRMING_SCHEDULE'
>('CONFIRMING_SCHEDULE');

export const confirmingScheduleReducer = createReducer<Report | null>(
  initialStateConfirm, // initial state
  (builder) => {
    builder.addCase(confirmingSchedule, (state, action) => {
      return action.payload;
    });
  }
);
// Delete
// Delete
type DeletingReport = Report | null;

const initialStateDelete: Report | null = null;

export const deletingSchedule = createAction<
  DeletingReport,
  'DELETING_SCHEDULE'
>('DELETING_SCHEDULE');

export const deletingScheduleReducer = createReducer<Report | null>(
  initialStateDelete, // initial state
  (builder) => {
    builder.addCase(deletingSchedule, (state, action) => {
      return action.payload;
    });
  }
);

// Edit form
type EditingReport = Report | null;

const initialStateEditForm: Report | null = null;

export const editingSchedule = createAction<EditingReport, 'EDITING_SCHEDULE'>(
  'EDITING_SCHEDULE'
);

export const editingScheduledReportReducer = createReducer<Report | null>(
  initialStateEditForm, // initial state
  (builder) => {
    builder.addCase(editingSchedule, (state, action) => {
      return action.payload;
    });
  }
);
