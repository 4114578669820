import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from '../../components/icons';

import useBackupToLocalStorage, {
  SIGN_UP_LOCAL_STORAGE_KEY,
} from './helpers/useBackupToLocalStorage';
import { REFERENCE_OPTIONS, sourcesToPrompt } from './helpers/reference-source';
import { assertAllValid, signupFormValidate } from './helpers/validation';
import { submitSignUp } from './helpers/api-requests';
import { datadogLogs } from '@datadog/browser-logs';

const deriveFriendlyErrorFromServer = (
  serverResponse: Response,
  responseBody: any
): string => {
  if (responseBody.type === 'invalid_url') {
    return 'Website URL is invalid. Please provide a valid website URL.';
  }

  if (responseBody.message.includes('email: failed to pass regex')) {
    return 'Email is invalid. Please provide a valid email value.';
  }

  if (serverResponse.status === 400) {
    if (responseBody.message.includes('maximum string length')) {
      return 'Something went wrong. Please provide values that are not very long.';
    }
  }

  return 'Something went wrong. Please try again.';
};

const ValidationError: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="validation-error">
      <span className="invalid-flag">
        <Icon name="alert-circle" />
      </span>
      <span className="validation-error-text">{text}</span>
    </div>
  );
};

const SignupForm: React.FC<{ onSuccessfulSignup: (email: string) => void }> = ({
  onSuccessfulSignup,
}) => {
  // Field values
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [partnerDomain, setPartnerDomain] = useState<string>('');
  const [monthlyImpressions, setMonthlyImpressions] = useState<number | null>(
    null
  );
  const [referenceSource, setReferenceSource] = useState<string>('');
  const [referenceReason, setReferenceReason] = useState<string>('');
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  // ---

  const [serverProvidedValidationError, setServerProvidedValidationError] =
    useState<string>('');
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [submittingToServer, setSubmittingToServer] = useState<boolean>(false);
  const [referenceLocked, setReferenceLocked] = useState<boolean>(false);

  const fullFormPayload = {
    firstName,
    lastName,
    contactEmail,
    partnerDomain,
    monthlyImpressions,
    referenceSource,
    referenceReason,
    agreedToTerms,
  };

  const savedData = useBackupToLocalStorage(fullFormPayload);
  useEffect(() => {
    if (savedData) {
      setFirstName(savedData.firstName || '');
      setLastName(savedData.lastName || '');
      setContactEmail(savedData.contactEmail || '');
      setPartnerDomain(savedData.partnerDomain || '');
      setMonthlyImpressions(savedData.monthlyImpressions || null);
      if (!referenceLocked) {
        setReferenceSource(savedData.referenceSource || '');
        setReferenceReason(savedData.referenceReason || '');
      }
      setAgreedToTerms(savedData.agreedToTerms || false);
    }
  }, [savedData, referenceLocked]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pluginName = params.get('plugin');
    if (pluginName) {
      setReferenceLocked(true);
      setReferenceSource('Plugin');
      setReferenceReason(pluginName);
    }
  }, []);

  const validationResults = signupFormValidate(fullFormPayload);
  // const allValid = assertAllValid(validationResults);

  const [
    firstNameValid,
    lastNameValid,
    contactEmailValid,
    partnerDomainValid,
    monthlyImpressionsValid,
    referenceSourceValid,
    agreedToTermsValid,
    referenceReasonValid,
  ]: Array<boolean> = [
    validationResults.firstName.length === 0,
    validationResults.lastName.length === 0,
    validationResults.contactEmail.length === 0,
    validationResults.partnerDomain.length === 0,
    validationResults.monthlyImpressions.length === 0,
    validationResults.referenceSource.length === 0,
    validationResults.agreedToTerms.length === 0,
    validationResults.referenceReason.length === 0,
  ];

  const [blurredFields, setBlurredFields] = useState({
    firstName: false,
    lastName: false,
    contactEmail: false,
    partnerDomain: false,
    monthlyImpressions: false,
    referenceReason: false,
  });

  const blurField = useCallback(
    (fieldName: string) => {
      setBlurredFields((currentFields) => ({
        ...currentFields,
        [fieldName]: true,
      }));
    },
    [setBlurredFields]
  );

  const fieldShouldShowError = (fieldName: string) => {
    switch (fieldName) {
      case 'firstName':
        return (
          !firstNameValid &&
          (blurredFields.firstName === true || submitAttempted)
        );
      case 'lastName':
        return (
          !lastNameValid && (blurredFields.lastName === true || submitAttempted)
        );

      case 'contactEmail':
        return (
          !contactEmailValid &&
          (blurredFields.contactEmail === true || submitAttempted)
        );
      case 'partnerDomain':
        return (
          !partnerDomainValid &&
          (blurredFields.partnerDomain === true || submitAttempted)
        );
      case 'monthlyImpressions':
        return (
          !monthlyImpressionsValid &&
          (blurredFields.monthlyImpressions === true || submitAttempted)
        );
      case 'referenceSource':
        return !referenceSourceValid && submitAttempted;
      case 'referenceReason':
        return (
          !referenceReasonValid &&
          (blurredFields.referenceReason === true || submitAttempted)
        );
      case 'agreeToTerms':
        return !agreedToTermsValid && submitAttempted;
      default:
        throw new Error(`Unexpected value: ${fieldName}`);
    }
  };

  const attemptSubmission: React.FormEventHandler = async (e) => {
    e.preventDefault();
    setServerProvidedValidationError('');
    setSubmitAttempted(true);

    const validationResults = signupFormValidate(fullFormPayload);
    const allValid = assertAllValid(validationResults);

    if (!allValid) return;

    try {
      setSubmittingToServer(true);
      const timeBeforeSignup = Date.now();
      await submitSignUp(fullFormPayload);
      const serverResponseMs = Date.now() - timeBeforeSignup;
      setTimeout(
        () => {
          localStorage.removeItem(SIGN_UP_LOCAL_STORAGE_KEY);
          onSuccessfulSignup(contactEmail);
        },
        Math.max(0, 2500 - serverResponseMs)
      );
    } catch (e: any) {
      setSubmittingToServer(false);

      if (typeof e.status !== 'undefined') {
        const response: Response = e as Response;
        const responseBody = await response.json();
        // This is an error from the server, likely validation.
        setServerProvidedValidationError(
          deriveFriendlyErrorFromServer(response, responseBody)
        );
      } else {
        datadogLogs.logger.error(
          'Failure to submit sign-up',
          fullFormPayload,
          e instanceof Error ? e : undefined
        );
      }
    }
  };

  return (
    <form
      id="sign-up-form"
      onSubmit={attemptSubmission}
      style={{ opacity: submittingToServer ? 0.3 : 1 }}
    >
      {serverProvidedValidationError !== '' && (
        <span className="general-validation-error">
          {serverProvidedValidationError}
        </span>
      )}
      <div id="sign-up-form-top-fields">
        <div className="field-container">
          <div className="field-heading">
            <label
              htmlFor="firstName"
              className={
                fieldShouldShowError('firstName') ? 'invalid-flag' : ''
              }
            >
              First name
            </label>
            {fieldShouldShowError('firstName') && (
              <ValidationError text={'First name is required.'} />
            )}
          </div>
          <input
            id="firstName"
            type="text"
            placeholder=""
            onBlur={() => blurField('firstName')}
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            className={fieldShouldShowError('firstName') ? 'invalid-flag' : ''}
          />
        </div>
        <div className="field-container">
          <div className="field-heading">
            <label
              htmlFor="lastName"
              className={fieldShouldShowError('lastName') ? 'invalid-flag' : ''}
            >
              Last name
            </label>
            {fieldShouldShowError('lastName') && (
              <ValidationError text={'Last name is required.'} />
            )}
          </div>
          <input
            id="lastName"
            type="text"
            placeholder=""
            onBlur={() => blurField('lastName')}
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            className={fieldShouldShowError('lastName') ? 'invalid-flag' : ''}
          />
        </div>
      </div>
      <div className="field-container">
        <div className="field-heading">
          <label
            className={
              fieldShouldShowError('contactEmail') ? 'invalid-flag' : ''
            }
            htmlFor="email"
          >
            Contact email
          </label>
          {fieldShouldShowError('contactEmail') && (
            <ValidationError text={'Must be a valid email.'} />
          )}
        </div>
        <input
          id="email"
          type="email"
          placeholder=""
          onBlur={() => blurField('contactEmail')}
          onChange={(e) => setContactEmail(e.target.value)}
          value={contactEmail}
          className={fieldShouldShowError('contactEmail') ? 'invalid-flag' : ''}
        />
      </div>
      <div className="field-container field-container--spaced ">
        <div className="field-heading">
          <label
            className={
              fieldShouldShowError('partnerDomain') ? 'invalid-flag' : ''
            }
            htmlFor="websiteURL"
          >
            Website URL
          </label>
          {fieldShouldShowError('partnerDomain') && (
            <ValidationError text={'Must be a valid URL.'} />
          )}
        </div>
        <div
          id="website-field-container"
          className={
            fieldShouldShowError('partnerDomain') ? 'invalid-flag' : ''
          }
        >
          <span id="static-protocol-badge">https://</span>
          <input
            id="websiteURL"
            type="text"
            placeholder=""
            onChange={(e) => setPartnerDomain(e.target.value)}
            onBlur={() => blurField('partnerDomain')}
            value={partnerDomain}
          />
        </div>
      </div>
      <div className="field-container field-container--spaced">
        <div className="field-heading">
          <label
            htmlFor="impressions"
            className={
              fieldShouldShowError('monthlyImpressions') ? 'invalid-flag' : ''
            }
          >
            Monthly impressions
          </label>
          {fieldShouldShowError('monthlyImpressions') && (
            <ValidationError text={'This field is required.'} />
          )}
        </div>
        <input
          id="impressions"
          type="number"
          step="1"
          min="0"
          placeholder="e.g. 99000"
          onBlur={() => blurField('monthlyImpressions')}
          onChange={(e) => setMonthlyImpressions(parseInt(e.target.value, 10))}
          value={monthlyImpressions || ''}
          className={
            fieldShouldShowError('monthlyImpressions') ? 'invalid-flag' : ''
          }
        />
      </div>
      <div className="field-container field-container--spaced">
        <div className="field-heading">
          <label htmlFor="source-dropdown">How did you hear about us?</label>
          {fieldShouldShowError('referenceSource') && (
            <ValidationError text={'Required.'} />
          )}
        </div>
        <select
          id="source-dropdown"
          disabled={referenceLocked}
          onChange={(e) => {
            setReferenceSource(e.target.value);
          }}
          value={referenceSource}
        >
          <option></option>
          {REFERENCE_OPTIONS.map((sourceOption) => {
            return (
              <option
                key={sourceOption.choiceValue}
                value={sourceOption.choiceValue}
              >
                {sourceOption.choiceValue}
              </option>
            );
          })}
        </select>
        {sourcesToPrompt[referenceSource] && (
          <div id="source-extra-field">
            <input
              id="otherReason"
              disabled={referenceLocked}
              placeholder={sourcesToPrompt[referenceSource]}
              onBlur={() => blurField('referenceReason')}
              onChange={(e) => setReferenceReason(e.target.value)}
              value={referenceReason}
              className={
                fieldShouldShowError('referenceReason') ? 'invalid-flag' : ''
              }
            />
            {fieldShouldShowError('referenceReason') && (
              <div id="reference-reason-error-container">
                <ValidationError
                  text={'Please provide a value up to 40 characters.'}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div id="checks-container">
        {fieldShouldShowError('agreeToTerms') && (
          <div
            id="terms-error"
            style={{
              visibility: fieldShouldShowError('agreeToTerms')
                ? 'visible'
                : 'hidden',
            }}
          >
            <span className="invalid-flag">
              <Icon name="alert-circle" />
            </span>
            <span className="validation-error">
              Please agree to Chicory{`'`}s terms and conditions, and privacy
              policy.
            </span>
          </div>
        )}

        <div className="check-entry">
          <input
            id="agreeToTerms"
            type="checkbox"
            onChange={(e) => setAgreedToTerms(e.target.checked)}
            checked={agreedToTerms}
          />
          <label htmlFor="agreeToTerms">
            I agree to Chicory{`'`}s{' '}
            <a
              href="https://chicory.co/terms-of-services"
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions, and privacy policy.
            </a>
          </label>
        </div>
      </div>
      <button disabled={submittingToServer} type="submit">
        Get Started
      </button>
    </form>
  );
};

export default SignupForm;
