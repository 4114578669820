import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import './Step2.pcss';
import { Icon } from '../../components/icons';
import { Tiles } from '../../components/Tiles';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Form } from '../../components/Form';
import RecipePageClientValidationSystem from '../../components/WebRecipeValidator/ValidationSystem';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import {
  reportValidatorErrors,
  reportValidatorSuccess,
} from '../../store/userSlice';

const PublishersMailtoLink = () => {
  return (
    <a
      href="mailto:publishers@chicory.co"
      target="_blank"
      rel="noreferrer noopener"
      className="blue-link"
    >
      publishers@chicory.co
    </a>
  );
};

const Step2Validate: React.FC = () => {
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useIsMobile();

  const [isValidating, setIsValidating] = useState(false);
  const [validationIssues, setValidationIssues] = useState<string[]>([]);
  const [validationStatus, setValidationStatus] = useState<
    'success' | 'fail' | 'not run'
  >('not run');
  const [url, setUrl] = useState('');
  const [exampleVisible, setExampleVisible] = useState(false);

  // Detect a previous successful scrape and auto-fill successful states.
  useEffect(() => {
    if (user !== null) {
      const installDetails = user.installationDetails;
      if (installDetails) {
        const installStatus = installDetails.onboardingStatus;
        const lastUrl = installDetails.lastValidationRecipeUrl;
        if (installStatus === 'scraper-validation-success' && lastUrl) {
          setValidationStatus('success');
          setUrl(lastUrl);
        }
      }
    }
  }, [user]);

  const handleSubmit = async () => {
    if (url === '') return;
    setIsValidating(true);
    const validator = new RecipePageClientValidationSystem();
    try {
      const validationResults = await validator.processURL(url);
      if (validationResults === true) {
        setValidationIssues([]);
        setValidationStatus('success');
        dispatch(reportValidatorSuccess(url));
      } else {
        setValidationIssues(validationResults);
        setValidationStatus('fail');
        dispatch(reportValidatorErrors({ url, errors: validationResults }));
      }
      setIsValidating(false);
    } catch (e) {
      console.error(e);
    }
  };

  // Small components, assembly order depends on isMobile
  const sentence1 = (
    <span className="sentence1">
      {`We need to validate the plugin installation and your site's content.`}
    </span>
  );
  const sentence2 = (
    <span style={isMobile ? { marginTop: '5px' } : {}}>
      {`Your website code needs to be formatted and structured in a way that will allow our technology and search engines to accurately interpret the ingredients and information in your recipe.`}
    </span>
  );
  const header = <h1 className="header">{`Step 2 of 3: Validating`}</h1>;
  const icon = (
    <div className="icon-holder">
      <Icon
        name="groceries"
        style={{
          width: isMobile ? '75px' : '150px',
          height: isMobile ? '75px' : '150px',
          marginRight: isMobile ? '20px' : '0',
          marginLeft: isMobile ? '5px' : '0',
        }}
      />
    </div>
  );
  const exampleText = (
    <p id="example-text">
      {`For example, if your recipe has 'butter', for Chicory and others to know
      you are referring to an actual ingredient that can also be identified as
      a product, proper schema will allow for that identification.`}
    </p>
  );
  const exampleFolded = (
    <div
      id="example-folded"
      onClick={() => {
        setExampleVisible((prev) => !prev);
      }}
      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
    >
      <Icon
        name="circleChevronRight"
        style={{ marginRight: '10px' }}
        className={`icon-container ${exampleVisible ? 'rotated' : ''}`}
      />
      <span>Example</span>
    </div>
  );
  // End small components

  return (
    <div id="onboarding-step2-container">
      {isMobile ? null : (
        <h2 id="getting-started__header">Getting started with Chicory</h2>
      )}
      <div className="broad-container">
        {isMobile ? null : icon}
        <div id="main-flex-column">
          {header}
          <div>
            {isMobile ? (
              <div id="mobile-icon-and-line-break">
                {icon}
                {sentence1}
              </div>
            ) : (
              sentence1
            )}
            {isMobile ? <div style={{ marginTop: '10px' }}></div> : null}
            {sentence2}
          </div>
          {/* Example toggle text */}
          {isMobile ? exampleFolded : exampleText}
          {exampleVisible ? exampleText : null}
          <p>
            <span>To do this, check </span>
            <a
              href="https://schema.org/Recipe"
              target="_blank"
              rel="noreferrer noopener"
              className="blue-link"
            >
              schema.org
            </a>
            <span>{`'s guidelines for examples.`}</span>
          </p>
          {isMobile && validationStatus === 'success' && <OnboardingSuccess />}
          {isMobile && validationStatus === 'fail' && (
            <OnboardingFail msgs={validationIssues} />
          )}
          {!isMobile && validationStatus === 'not run' && (
            <p className="no-top-margin">
              Once you are ready, you can validate your site structure by
              providing a recipe from your site here:
            </p>
          )}
          <Form.Label>
            {validationStatus === 'fail' ? 'Try again:' : 'Recipe URL:'}
          </Form.Label>
          <div className="wrap-on-mobile">
            <Form.InputContainer
              style={{
                width: '100%',
                marginRight: '10px',
                lineHeight: '45px',
              }}
            >
              <input
                value={url}
                type="url"
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </Form.InputContainer>
            {isValidating ? (
              <div id="spinner-container">
                <div id="spinner"></div>
              </div>
            ) : (
              <Tiles
                style={{
                  width: isMobile ? '100%' : '120px',
                  height: '45px',
                  border: 'none',
                  justifyContent: 'space-between',
                }}
              >
                <Tiles.Button
                  version="lightPurple"
                  style={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 400,
                    border: 'none',
                  }}
                  onClick={() => handleSubmit()}
                >
                  Run test
                </Tiles.Button>
              </Tiles>
            )}
          </div>
          {!isMobile && validationStatus === 'success' && <OnboardingSuccess />}
          {!isMobile && validationStatus === 'fail' && (
            <OnboardingFail msgs={validationIssues} />
          )}
          {!isMobile && (
            <p>
              If you have any questions or need support with installation,
              please reach out to <PublishersMailtoLink />.
            </p>
          )}
          <Tiles
            style={{
              width: '100%',
              height: 'auto',
              border: 'none',
              justifyContent: 'space-between',
              marginBottom: '90px',
              flexDirection: isMobile ? 'column-reverse' : 'row',
            }}
          >
            <Link
              to={{ pathname: '/onboarding/step1' }}
              style={{
                width: isMobile ? '100%' : '30%',
              }}
            >
              <Tiles.Button
                version="clear"
                style={{
                  width: isMobile ? '100%' : '30%',
                  height: '45px',
                  fontSize: '16px',
                  fontWeight: 100,
                  color: 'purple',
                  border: 'none',
                  justifyContent: 'flex-start',
                  paddingLeft: '0',
                  marginTop: '15px',
                }}
              >
                Previous
              </Tiles.Button>
            </Link>
            {validationStatus !== 'not run' && (
              <Link
                to={{ pathname: '/onboarding/step3' }}
                style={{
                  width: isMobile ? '100%' : '180px',
                }}
              >
                <Tiles.Button
                  version="darkPurple"
                  style={{
                    width: isMobile ? '100%' : '180px',
                    height: '45px',
                    fontSize: '16px',
                    fontWeight: 400,
                    border: 'none',
                    marginTop: '15px',
                  }}
                >
                  {validationStatus === 'fail' ? 'Correct this later' : 'Next'}
                </Tiles.Button>
              </Link>
            )}
          </Tiles>
        </div>
      </div>
    </div>
  );
};

export default Step2Validate;

const OnboardingSuccess = () => {
  const isMobile = useIsMobile();
  return (
    <div className="onboarding-success">
      <div style={{ fontWeight: 400 }}>Success!</div>
      <div>
        Your site is correctly formatted and you can begin using Chicory right
        away.
      </div>
      <Icon
        name="checkCircle2"
        style={{
          width: isMobile ? '50px' : '75px',
          height: '75px',
          opacity: '100%',
          position: 'absolute',
          left: '-30px',
        }}
      />
    </div>
  );
};

const issueMessageMap: {
  [k: string]: { header: string; body: () => string | React.JSX.Element };
} = {
  ['Missing installation script']: {
    header: 'Uh oh.',
    body: () => {
      return (
        <span>
          We were not able to detect our code on your page. Please try again or
          for support with installation contact <PublishersMailtoLink />.
        </span>
      );
    },
  },
  ['Missing valid schema']: {
    header: 'We were not able to parse a recipe schema.',
    body: () => 'Please include a recipe schema in JSON-LD format.',
  },
};

const translateRawIssueToFormattedLanguage = (rawIssueText: string) => {
  return (
    issueMessageMap[rawIssueText] || {
      header: 'Uh oh.',
      body: () => rawIssueText,
    }
  );
};
const OnboardingFail = ({ msgs }: { msgs: string[] }) => {
  const formattedMessages = msgs.map(translateRawIssueToFormattedLanguage);

  const isMobile = useIsMobile();
  const [pageNum, setPageNum] = useState(0);
  const [direction, setDirection] = useState<null | 'right' | 'left'>(null);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (pageNum < msgs.length - 1) {
        setDirection('left');
        setTimeout(() => {
          setPageNum((prev) => prev + 1);
          setDirection(null);
        }, 200);
      }
    },
    onSwipedRight: () => {
      if (pageNum > 0) {
        setDirection('right');
        setTimeout(() => {
          setPageNum((prev) => prev - 1);
          setDirection(null);
        }, 200);
      }
    },
    preventScrollOnSwipe: true, // lock body
    // delta: 50, // distance before swipe begins
    trackMouse: true, // allow mouse swiping
  });

  const currMsg = formattedMessages[pageNum];
  const nextMsg = formattedMessages[pageNum + 1];
  const prevMsg = formattedMessages[pageNum - 1];

  return (
    <div className="onboarding-fail" {...(isMobile ? swipeHandlers : {})}>
      <div className="cards-container-clip-overflow">
        {/* prev */}
        {prevMsg && (
          <div
            className={`msg ${direction === 'right' ? 'start-sliding-right' : 'waiting-left'}`}
          >
            <div style={{ fontWeight: 400 }}>{prevMsg?.header}</div>
            <div>{prevMsg?.body()}</div>
          </div>
        )}
        {/* curr */}
        <div
          className={`msg current ${direction ? `sliding-${direction}` : ''}`}
        >
          <div style={{ fontWeight: 400 }}>{currMsg?.header}</div>
          <div className="allow-scroll">{currMsg?.body()}</div>
        </div>
        {/* next */}
        {nextMsg && (
          <div
            className={`msg ${direction === 'left' ? 'start-sliding-left' : 'waiting-right'}`}
          >
            <div style={{ fontWeight: 400 }}>{nextMsg?.header}</div>
            <div>{nextMsg?.body()}</div>
          </div>
        )}
      </div>

      <Icon
        name="exclamationOrange"
        style={{
          width: isMobile ? '50px' : '75px',
          height: isMobile ? '50px' : '75px',
          position: 'absolute',
          left: '-25px',
          top: '30px',
        }}
      />
      {formattedMessages.length > 1 && (
        <div className="fail-pagination">
          {!isMobile && pageNum > 0 && (
            <span
              onClick={() => setPageNum((prev) => prev - 1)}
              className="pagination-caret left"
            >
              &lt;
            </span>
          )}
          {`${pageNum + 1} of ${formattedMessages.length}`}
          {!isMobile && pageNum < formattedMessages.length - 1 && (
            <span
              onClick={() => setPageNum((prev) => prev + 1)}
              className="pagination-caret right"
            >
              &gt;
            </span>
          )}
        </div>
      )}
      {isMobile && msgs.length > 1 && (
        // large pagination carets
        <div className="pagination-caret--large">
          {pageNum > 0 && (
            <Icon
              height={'60px'}
              width={'60px'}
              name="chevron-down"
              style={{ cursor: 'pointer' }}
              onClick={() => setPageNum((prev) => prev - 1)}
              className="onboarding-fail__chevron left"
            />
          )}
          {pageNum < msgs.length - 1 && (
            <Icon
              height={'60px'}
              width={'60px'}
              name="chevron-down"
              style={{ cursor: 'pointer' }}
              onClick={() => setPageNum((prev) => prev + 1)}
              className="onboarding-fail__chevron right"
            />
          )}
        </div>
      )}
    </div>
  );
};
