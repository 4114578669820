import { useState, useEffect } from 'react';
import debounce from 'debounce';

import { MOBILE_VIEW_MAX_WIDTH } from '../config';

/*
  Use one global event listener for all components
  On resize, the isMobile bool will be broadcast
  to each component having an instance of useIsMobile
*/
let listeners: Array<(isMobile: boolean) => void> = [];

const notifyListeners = debounce(() => {
  const isMobile = window.innerWidth < MOBILE_VIEW_MAX_WIDTH;
  listeners.forEach((listener) => listener(isMobile));
}, 500);

window.addEventListener('resize', notifyListeners);

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < MOBILE_VIEW_MAX_WIDTH
  );

  useEffect(() => {
    const updateIsMobile = (newIsMobile: boolean) => {
      if (isMobile !== newIsMobile) {
        setIsMobile(newIsMobile);
      }
    };

    listeners.push(updateIsMobile);

    return () => {
      // Remove listener when component unmounts
      listeners = listeners.filter((listener) => listener !== updateIsMobile);
    };
  }, [isMobile]);

  return isMobile;
};
