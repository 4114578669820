import {
  createReducer,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { UserState, Publisher, InstallationPlatform } from '../types';
import { RootState, AppDispatch } from '.';
import { chicoryFetcher } from '../utils';
import { setFeatureFlags } from './featureFlags';
import { availablePubsAction, selectedPubsAction } from './publisherSlice';
import { datadogLogs } from '@datadog/browser-logs';

type IUserInstruction = {
  fetchedEmail: string;
  fetchedName: string;
  fetchedPicture: string;
  hasPremium?: boolean;
  role: 'partner' | 'admin';
  publishers: Publisher[];
  paypalEmail?: string;
  installationDetails?: {
    platform: InstallationPlatform | null;
    lastValidationRecipeUrl: string | null;
    onboardingStatus:
      | 'scraper-validation-success'
      | 'injection-validation-success'
      | null;
  };
};

export const logoutAction = createAction('LOGOUT');

export const login = createAsyncThunk<
  IUserInstruction,
  void,
  { state: RootState; dispatch: AppDispatch }
>('LOGIN', async (_, { getState, dispatch }) => {
  const data = await chicoryFetcher('/auth/profile').then((response) =>
    response.json()
  );
  if (data.showImpressions) {
    dispatch(setFeatureFlags({ showImpressions: true }));
  }

  const state = getState();

  const user: IUserInstruction = {
    fetchedEmail: data.email,
    fetchedName: data.name,
    fetchedPicture: data.picture,
    paypalEmail: data.paypalEmail,
    role: data.role,
    publishers: data.publishers,
    installationDetails: data.installationDetails,
  };

  // Ensure pubs in url are valid, remove if not
  const availablePubIds = user.publishers.map((pub) => pub.id);
  const validSelectedPubs = state.pubs.selectedPubs.filter((selectedPubId) =>
    availablePubIds.includes(selectedPubId)
  );

  dispatch(availablePubsAction({ availablePubs: user.publishers }));

  if (state.pubs.selectedPubs.length !== validSelectedPubs.length) {
    dispatch(selectedPubsAction({ selectedPubs: validSelectedPubs }));
  }

  return user;
});

export const updateInstallationPlatform = createAsyncThunk<
  InstallationPlatform,
  InstallationPlatform
>('UPDATE_INSTALLATION_PLATFORM', async (installationOption) => {
  await chicoryFetcher('/installation-details', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      platform: installationOption,
    }),
  });
  return installationOption;
});

export const updatePaypalEmail = createAsyncThunk<string, string>(
  'UPDATE_PAYPAL_EMAIL',
  async (email) => {
    await chicoryFetcher('/auth/profile', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paypalEmail: email,
      }),
    });
    return email;
  }
);

export const reportValidatorErrors = createAsyncThunk<
  null,
  { url: string; errors: string[] }
>('REPORT_VALIDATOR_ERRORS', async (report) => {
  await chicoryFetcher('/installation-details', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lastValidationRecipeUrl: report.url,
      appendScraperValidations: report.errors,
    }),
  });
  return null;
});

export const reportValidatorSuccess = createAsyncThunk<null, string>(
  'REPORT_VALIDATOR_SUCCESS',
  async (url) => {
    await chicoryFetcher('/installation-details', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lastValidationRecipeUrl: url,
        onboardingStatus: 'scraper-validation-success',
      }),
    });
    return null;
  }
);

// Define the reducer functions using createReducer
const initialUser: null = null;
export const userReducer = createReducer<UserState>(initialUser, (builder) => {
  builder.addCase(login.fulfilled, (_, action) => {
    return {
      email: action.payload.fetchedEmail,
      paypalEmail: action.payload.paypalEmail,
      name: action.payload.fetchedName,
      picture: action.payload.fetchedPicture,
      role: action.payload.role,
      publishers: action.payload.publishers,
      hasPremium: action.payload.publishers.some(
        (pub) => pub.isPremium === true
      ),
      installationDetails: action.payload.installationDetails,
    };
  });
  builder.addCase(login.rejected, (_, { error }) => {
    console.error('login failed', error);
  });
  builder.addCase(logoutAction, () => {
    return null;
  });
  builder.addCase(updateInstallationPlatform.fulfilled, (user, action) => {
    if (user && user.installationDetails) {
      user.installationDetails.platform = action.payload;
    }
  });
  builder.addCase(updateInstallationPlatform.rejected, (user, { error }) => {
    datadogLogs.logger.error(
      'Could not update user profile installation platform',
      error
    );
  });
  builder.addCase(updatePaypalEmail.fulfilled, (user, action) => {
    if (user) {
      user.paypalEmail = action.payload;
    }
  });
  builder.addCase(updatePaypalEmail.rejected, (user, { error }) => {
    datadogLogs.logger.error(
      'Could not update user profile PayPal email',
      error
    );
  });
  builder.addCase(reportValidatorErrors.rejected, (_, { error }) => {
    datadogLogs.logger.error('Could not send validator error report', error);
  });
});
