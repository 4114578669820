import type { SignupFormUserInput } from '../types';
import { apiUrl } from '../../../config';
const fetch = window.fetch;

export const contactHealth = async () => {
  return await fetch(`${apiUrl}/health`);
};

type ServerSignUpRequestBody = {
  firstName: string;
  lastName: string;
  email: string;
  source: string;
  sourceComments?: string;
  websiteURL: string;
  monthlyImpressions: number;
};

const formatSignupDataForServer = (userInput: SignupFormUserInput): string => {
  const requestBody: ServerSignUpRequestBody = {
    firstName: '',
    lastName: '',
    email: '',
    source: '',
    websiteURL: '',
    monthlyImpressions: 0,
  };

  requestBody.firstName = userInput.firstName;
  requestBody.lastName = userInput.lastName;
  requestBody.email = userInput.contactEmail;
  requestBody.source = userInput.referenceSource;
  if (userInput.referenceReason) {
    requestBody.sourceComments = userInput.referenceReason;
  }
  requestBody.websiteURL = userInput.partnerDomain;
  if (userInput.monthlyImpressions === null) {
    throw new Error(
      'Monthly impressions is null from user after validation. Unexpected error.'
    );
  }
  requestBody.monthlyImpressions = userInput.monthlyImpressions;

  return JSON.stringify(requestBody);
};

export const submitSignUp = async (userInput: SignupFormUserInput) => {
  const requestBody = formatSignupDataForServer(userInput);

  const serverResponse = await fetch(`${apiUrl}/signup`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CHICORY-CSRF-PROTECTION': '1',
    },
    body: requestBody,
  });

  if (serverResponse.status === 204) {
    return true;
  } else {
    throw serverResponse;
  }
};
