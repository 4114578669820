// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#onboarding-step4-container p {
    margin-bottom: 0;
  }
  #onboarding-step4-container .sentence1 {
    align-self: center
  }
  @media (max-width: 440px) {

  #onboarding-step4-container .sentence1 {
      margin-bottom: 15px
  }
    }
  #onboarding-step4-container .broad-container {
    font-size: 16px;
    font-weight: 100;
    margin-right: 2vw;
    max-width: 840px;
    display: flex
  }
  @media (max-width: 707px) {

  #onboarding-step4-container .broad-container {
      margin-right: 0
  }
    }
  #onboarding-step4-container .getting-started__header {
    font-size: 24px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  #onboarding-step4-container .header {
    font-size: 34px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px
  }
  @media (max-width: 707px) {

  #onboarding-step4-container .header {
      font-size: 24px;
      margin-top: 10px;
      margin-bottom: 15px
  }
    }
  #onboarding-step4-container .main-flex-column {
    display: flex;
    flex-direction: column;
  }
  #onboarding-step4-container .mobile-icon-and-line-break {
    display: flex;
  }
  #onboarding-step4-container .icon-holder {
    position: relative;
    top: -10px
  }
  @media (max-width: 707px) {

  #onboarding-step4-container .icon-holder {
      top: 0px;
      float: left;
      height: 103px
  }
    }
`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Step4.pcss"],"names":[],"mappings":"AAIE;IACE,gBAAgB;EAClB;EAEA;IACE;EAIF;EAHE;;EAFF;MAGI;EAEJ;IADE;EAGF;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB;EAIF;EAHE;;EANF;MAOI;EAEJ;IADE;EAEF;IACE,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,mBAAmB;EACrB;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb;EAMF;EALE;;EALF;MAMI,eAAe;MACf,gBAAgB;MAChB;EAEJ;IADE;EAGF;IACE,aAAa;IACb,sBAAsB;EACxB;EAEA;IACE,aAAa;EACf;EAEA;IACE,kBAAkB;IAClB;EAMF;EALE;;EAHF;MAII,QAAQ;MACR,WAAW;MACX;EAEJ;IADE","sourcesContent":["$margin-for-icon: 180px;\n$mobile: 707px;\n\n#onboarding-step4-container {\n  p {\n    margin-bottom: 0;\n  }\n\n  .sentence1 {\n    align-self: center;\n    @media (max-width: 440px) {\n      margin-bottom: 15px;\n    }\n  }\n\n  .broad-container {\n    font-size: 16px;\n    font-weight: 100;\n    margin-right: 2vw;\n    max-width: 840px;\n    display: flex;\n    @media (max-width: $mobile) {\n      margin-right: 0;\n    }\n  }\n  .getting-started__header {\n    font-size: 24px;\n    font-weight: 400;\n    margin-top: 0px;\n    margin-bottom: 30px;\n  }\n\n  .header {\n    font-size: 34px;\n    font-weight: 700;\n    margin-top: 0;\n    margin-bottom: 20px;\n    @media (max-width: $mobile) {\n      font-size: 24px;\n      margin-top: 10px;\n      margin-bottom: 15px;\n    }\n  }\n\n  .main-flex-column {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .mobile-icon-and-line-break {\n    display: flex;\n  }\n\n  .icon-holder {\n    position: relative;\n    top: -10px;\n    @media (max-width: $mobile) {\n      top: 0px;\n      float: left;\n      height: 103px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
