import type {
  SignupFormUserInput,
  SignupFormValidationResults,
} from '../types';
import isUrl from 'validator/lib/isURL';
import RFC822EmailRegex from './email-regex';
import { sourcesToPrompt } from './reference-source';

export const assertAllValid = (results: SignupFormValidationResults) => {
  return Object.values(results).every((errorSet) => errorSet.length === 0);
};

const verifyEmail = (emailValue: string) => RFC822EmailRegex.test(emailValue);

export const signupFormValidate = (
  userInput: SignupFormUserInput
): SignupFormValidationResults => {
  const validationResults: SignupFormValidationResults = {
    firstName: [], // required
    lastName: [], // required
    contactEmail: [], // required, email
    partnerDomain: [], // required, url
    monthlyImpressions: [], // required, not negative
    referenceSource: [], // required
    agreedToTerms: [], // must be true
    referenceReason: [], // max 40 chars
  };

  const requiredFields: Array<keyof SignupFormUserInput> = [
    'firstName',
    'lastName',
    'contactEmail',
    'partnerDomain',
    'monthlyImpressions',
    'referenceSource',
  ];
  requiredFields.forEach((fieldName) => {
    const fieldValue = userInput[fieldName];
    if (fieldValue === '' || fieldValue === null) {
      validationResults[fieldName].push('required');
    } else if (typeof fieldValue === 'number' && isNaN(fieldValue)) {
      validationResults[fieldName].push('required');
    }
  });

  const { contactEmail } = userInput;
  if (contactEmail !== '') {
    if (!verifyEmail(contactEmail))
      validationResults['contactEmail'].push('emailValue');
  }

  const {
    partnerDomain,
    monthlyImpressions,
    agreedToTerms,
    referenceSource,
    referenceReason,
  } = userInput;

  if (partnerDomain === '' || !isUrl(`https://` + partnerDomain)) {
    validationResults['partnerDomain'].push('urlValue');
  }
  if (monthlyImpressions !== null && monthlyImpressions < 0) {
    validationResults['monthlyImpressions'].push('positive');
  }
  if (agreedToTerms !== true) {
    validationResults['agreedToTerms'].push('agree');
  }

  // Certain selected reference sources will show an extra field for "reason".
  // A reason should be filled in, min length 1,
  // but reasons should not be longer than 40 characters.
  // These rules are imposed in the Chicory Network data source.
  if (referenceSource && sourcesToPrompt[referenceSource]) {
    if (referenceReason.length === 0) {
      validationResults['referenceReason'].push('required');
    } else if (referenceReason.length > 40) {
      validationResults['referenceReason'].push('maxLength');
    }
  }

  return validationResults;
};
