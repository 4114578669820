import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import chicoryLogoWhite from '../../assets/images/chicory-logo-flat-white.png';
import chicoryBannerSquares from '../../components/icons/chicory-banner-squares.svg';
import './OnboardingLayout.pcss';

const OnboardingLayout: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 200);
  }, []);

  useEffect(() => {
    document.title = 'Installing Chicory';
  }, []);

  return (
    <main id="onboarding-layout__container" style={{ opacity: loaded ? 1 : 0 }}>
      <header id="onboarding-layout__header">
        <img id="logo" src={chicoryLogoWhite} />
        <div
          id="chicory-banner-squares"
          dangerouslySetInnerHTML={{ __html: chicoryBannerSquares }}
        />
      </header>
      <section id="onboarding-layout__body">
        <Outlet />
      </section>
      <footer id="onboarding-layout__footer">
        <a href="https://chicory.co" target="_blank" rel="noreferrer">
          {`© Chicory ${new Date().getFullYear()}`}
        </a>
        <div className="w-spacer" />
        <div className="w-spacer" />
        <div className="w-spacer" />
        <a
          href="https://chicory.co/terms-of-services"
          target="_blank"
          rel="noreferrer"
        >
          TERMS
        </a>
        <div className="w-spacer" />
        <div className="w-spacer" />
        <div className="w-spacer" />
        <a
          href="https://chicory.co/product-privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          PRIVACY
        </a>
      </footer>
    </main>
  );
};

export default OnboardingLayout;
