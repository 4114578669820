import React, { useState } from 'react';

import { TileType, ChildrenProps, VersionProps } from '../types';
import './Tiles.pcss';

export const Tiles: TileType<ChildrenProps> = ({
  children,
  ...props
}: ChildrenProps) => {
  return (
    <div className="tile-wrapper" {...props}>
      {children}
    </div>
  );
};

Tiles.Tile = function Tile({
  children,
  selected,
  selectedClass = 'highlight-purple',
  ...props
}: ChildrenProps) {
  if (selected) {
    return (
      <div className={`tile ${selectedClass}`} {...props}>
        {children}
      </div>
    );
  } else {
    return (
      <div className={`tile`} {...props}>
        {children}
      </div>
    );
  }
};

Tiles.Button = function Button({ children, onClick, ...props }: VersionProps) {
  const { version = 'white' } = props;

  const [, setClicked] = useState(false);
  // lightPurple
  // white
  // darkPurple
  // red
  // clear
  const additionalClassNames = props.className;
  const cssVersion = `button ${additionalClassNames ?? ''} button__${version}`;

  return (
    <div
      {...props}
      className={cssVersion}
      onClick={(event) => {
        setClicked((prev) => !prev);
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {children}
    </div>
  );
};
